import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const apiUrl = process.env.REACT_APP_NEW_BASE_URL;
const appUrl = process.env.REACT_APP_NEW_BASE_URL;
const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Actionabl</title>
        <meta
          name="description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
        <meta property="og:title" content="About Us | Actionabl" />
        <meta
          property="og:description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${apiUrl}/og-img/Actionabl-About-Us.jpg`}
        />{" "}
        <meta
          name="twitter:image"
          content={`${apiUrl}/og-img/Actionabl-About-Us.jpg`}
        />
        <meta name="twitter:title" content="About Us | Actionabl" />
        <meta
          name="twitter:description"
          content="Driving product innovation and creating impactful customer experiences through a glimpse into the journey of the Actionabl platform."
        />
      </Helmet>

      <section className="bg-gray-100 min-h-screen font-satoshi">
        <div
          className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 mb-8 bg-cover bg-center"
          style={{
            backgroundImage: "url('/og-img/Actionabl-About-Us-Background.jpg')",
          }}
        >
          <h1 className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-left font-bold leading-tight pl-8 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            Driving Product Innovation & Fostering <br /> Impactful Customer
            Experiences
          </h1>
        </div>

        <div className="w-full px-4 sm:px-6 md:px-10 lg:px-12 py-6 md:py-8 lg:py-10 xl:py-12 text-gray-700 leading-relaxed mx-auto mb-8 shadow-lg rounded-lg">
          <div className="mt-4 pr-4 md:pr-[15px]">
            <Link to="/" className="text-blue-500 hover:underline">
              Home
            </Link>
            <span className="text-gray-600 mx-2"> &gt; </span>
            <span className="text-gray-800">About Us</span>
          </div>
          <br />
          <p className="mb-4 text-sm sm:text-base">
            Actionabl began as more than a vision it was a shared mission to
            redefine how businesses leverage RPA, OCR, AI to streamline and
            automate complex processes, sparking a new era of product
            innovation. Founded by visionary technologists and business leaders,
            our journey is rooted in a commitment to empowering organizations in
            the digital age with transformative capabilities designed to deliver
            impactful customer experiences.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            The Spark of Transformation
          </h1>
          <p className="mb-4 text-sm sm:text-base">
            From the outset, our founders envisioned a new path for enterprise
            platforms, daring to ask: “What if work itself could be
            fundamentally transformed?” They envisioned not merely an evolution
            but a revolution in enterprise operations—a bold reimagining where
            work is seamlessly orchestrated and driven autonomously.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            The Origin of Actionabl: A Vision for Tomorrow’s Challenges
          </h1>
          <p className="mb-4 text-sm sm:text-base">
            The Actionabl Platform emerged as the answer to tomorrow's most
            pressing challenges. But this was not just another software update
            or technology integration. This was product innovation in its truest
            form—a futuristic platform of Business Automation with the vision of
            an Autonomous Enterprise at its core. Our guiding principle,
            "Shaping the Future of Autonomous Enterprise," has never been just a
            slogan; it’s the driving force behind a platform that redefines the
            way businesses operate and scale.
          </p>

          <p className="mb-4 text-sm sm:text-base">
            Every feature of Actionabl is designed with the future in mind
            empowering businesses to stay ahead of the curve, foster impactful
            customer experiences, and unlock new levels of efficiency,
            resiliency, and agility. The platform evolves alongside
            technological advances, ensuring that organizations can adapt to the
            changing landscape of business operations and continue innovating
            with confidence.
          </p>

          <h1 className="text-black text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-6 pt-4 sm:pt-8 md:pt-10 lg:pt-12">
            Today and Beyond: Leading the Way to the Autonomous Enterprise
          </h1>
          <p className="mb-4 text-sm sm:text-base">
            Today, Actionabl stands at the forefront of Business Orchestration
            and Automation, delivering next-gen automation solutions that shape
            the future of technology. Our commitment to continuous innovation
            and meaningful change ensures that we’re not just meeting today’s
            needs—we’re anticipating tomorrow’s challenges and opportunities. As
            we look to the future, our mission remains clear: to be the leader
            in Autonomous enterprise operations, enabling companies to operate
            smarter, more efficiently, and more agilely. With our guiding
            principles, we’re excited to continue driving the future of business
            automation, one breakthrough solution at a time.
          </p>
        </div>
        <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center mb-4">
            Key <span style={{ color: "#1E429F" }}>leaders</span>
          </h2>

          <div className="grid grid-cols-1 gap-6 sm:gap-8 md:gap-10 mt-6 sm:mt-8 md:mt-10 mb-6">
            {/* Card 1 */}
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a1.jpg`}
                  alt="Chiman Jagani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Chiman Jagani
                </h3>
                <p className="text-lg " style={{ color: "#1E429F" }}>
                  Co-Founder
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  As an IIT Bombay alumnus, chiman brings over 35 years of
                  extensive IT experience as a Technology & Innovation Lead. He
                  has delivered large-scale enterprise solutions for clients
                  across North America, Europe, and Asia. A visionary and
                  technology Innovator, Chiman possesses a profound
                  understanding of the past and a forward-thinking perspective
                  for the future, making him a valuable asset in driving growth
                  and innovation.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:mr-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a2.jpg`}
                  alt="Chetan Panchani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Chetan Panchani
                </h3>
                <p className="text-lg " style={{ color: "#1E429F" }}>
                  Co-Founder
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  With a background at McKinsey & Company and over 20 years of
                  experience in digital transformation, business process
                  management, and process automation, Chetan is seasoned in
                  driving change. He has major transformations across North
                  America, empowering large organizations to transform and
                  innovate rapidly.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Hiren Panchani.jpg`}
                  alt="Hiren Panchani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Hiren Panchani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Business Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Hiren is a visionary leader with over 15 years of experience
                  in digital transformation space, empowering businesses to
                  achieve full operational autonomy. As Chief Business Officer,
                  he will drive strategic planning, business operations,
                  financial management, culture, communications, partnerships,
                  collaboration etc and responsible Actionabl’s business
                  development & growth. Hiren's expertise lies in helping
                  organizations unlock their true potential through agile and
                  resilient strategies as well as transformative approaches.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:mr-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Rakesh Datta.jpg`}
                  alt="Rakesh Datta "
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Rakesh Datta
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Sales Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Rakesh Datta is a true sales leader with over 20 years of
                  experience in driving business growth and strategic
                  initiatives related to sales. He has worked with industry
                  leaders such as Oracle, Jiffi.ai etc. As Chief Sales Officer,
                  Rakesh will execute strategical sales initiatives and
                  responsible for leading sales team as well as driving revenue
                  growth.
                </p>
              </div>
            </div>

            {/* {Card 5} */}
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:ml-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Anurag-Saxena.jpg`}
                  alt="Rakesh Datta "
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Anurag Saxena
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Partnership Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  With over 25 years of multidisciplinary experience in disruptive technology space, Anurag has scaled businesses in organizations like Hitachi, Wipro, OpenText, CoreStack. As Chief Partnership Officer, he builds strategic partnerships and alliances to drive innovation and growth for Actionabl. His rich expertise in crafting rewarding partnerships and in channel sales helps align collaborations with company vision, unlocking new opportunities and strengthening market presence.
                </p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden w-48 sm:w-52 md:w-56 lg:w-60 h-48 sm:h-52 md:h-56 lg:h-60 mb-4 lg:mb-0 lg:mr-8 bg-red-200 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a3.jpg`}
                  alt="Rakesh Datta "
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-3/5">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Sarvesh Renghe
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Customer Success Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Sarvesh Renghe is a highly experienced professional with over
                  30 years in software development. As Chief Customer Success
                  Officer, He leads the customer success function, ensuring
                  clients achieve their goals and realize the full value of the
                  company’s solutions. He excels in driving customer engagement,
                  managing implementations, and building trusted partnerships.
                  Sarvesh fosters a collaborative, growth-focused environment,
                  ensuring every customer interaction is impactful and aligned
                  with business objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
