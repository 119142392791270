import React from 'react';

const Ecosystem = () => {
  return (
    <div className="bg-white py-12 mt-8"> {/* Increased from py-10 to py-12 */}
      <h1 className="text-center text-5xl font-bold mb-10">
        Actionabl Ecosystem
      </h1>
      <div className="flex flex-col items-center gap-8 md:flex-row md:justify-center md:gap-12 lg:gap-16">

        <img
          src="/client/HDFC.png"
          style={{ paddingLeft: "23px" }}
          alt="HDFC"
          className="h-14 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90"
        />
        <img
          src="/client/Kotak Group.jpg"
          alt="Kotak Group"
          className="
            h-14 
            pr-5 ml-7         // Default padding-right: 20px, margin-left: ~28px
            sm:ml-8 sm:pr-6   // Small screens: adjust to 32px left margin, 24px right padding
            md:ml-10 md:pr-8  // Medium screens: adjust to 40px left margin, 32px right padding
            lg:ml-12 lg:pr-10 // Large screens: adjust to 48px left margin, 40px right padding
            transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90
          "
        />
        <img
          src="/client/lulu-financial-holdings.png"
          alt="Lulu Group"
          className="h-28 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90 pr-6"
        />

          
      <img
          src="/client/featsystems-logo.svg"
          height="56"
          width="226"
          alt="featsystems"
          className="h-28 transition-transform duration-500 ease-out transform hover:translate-y-2 hover:scale-105 hover:opacity-90"
        />
      
      </div>
    </div>
  );
};

export default Ecosystem;
